import Link from 'next/link';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Card, { CardContent, CardMedia } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import Props from '@/components/app/home/components/best-products/top/best/type';
import Star from '@/components/shared/components/icon/star';
import SaleAmount from '@/components/shared/components/sale-amount';

import imagesList from '@/constant/images-list';
import localization from '@/constant/localization';

export default function Best({ cover, title, rate, saleAmount, slug }: Props) {
  return (
    <Card noShadow className='rounded-none border-0 border-b-2 border-[#F5F6F9] '>
      <Container className='relative h-[209px] w-full max-w-[351px]'>
        <CardMedia
          src={cover.publicLink}
          alt={cover.title}
          className='h-[213px] w-full rounded-md object-top'
          fill
        />
        <XImage
          src={imagesList.icons.bookmarkIcon}
          alt={localization.zhaket}
          width={32}
          height={30}
          className='absolute bottom-4 left-4'
        />
      </Container>

      <CardContent className='pb-2'>
        <Text size={SIZE_ENUM.MD} className='line-clamp-1 text-[#424244] hover:text-[#ff9606]'>
          <Link href={`/web/${slug}`} prefetch={false}>{title}</Link>
        </Text>
        <Container className='mt-1 flex items-center gap-3'>
          <Star value={rate.rateAverage} reverse />
          <SaleAmount value={saleAmount} reverse />
        </Container>
      </CardContent>
    </Card>
  );
}
