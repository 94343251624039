'use client';
// import debounce from 'lodash.debounce';
// import { useCallback, useEffect, useRef, useState } from 'react';
// import cn from '@/lib/clsxm';
// import { useClientSmallScreen } from '@/lib/user-agent/client';
// import { useOutsideAlerter } from '@/components/app/home/helpers/hooks';
// import { useGetSearchSuggestion } from '@/components/layout/services/hooks';
// import PreviousSearch from '@/components/shared/components/search/previous-search';
// import Result from '@/components/shared/components/search/result';
// import Suggests from '@/components/shared/components/search/suggests';
// import { useGetSearch } from '@/components/shared/services/hooks';
// import { useSearchStore } from '@/store/search.store';
import { CiSearch } from 'react-icons/ci';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import { VARIANT_ENUM } from '@/components/@base/button/type';
import Container from '@/components/@base/container';
import Input from '@/components/@base/input/input';
import SearchModal from '@/components/shared/components/search/search-modal';

import useCommonModalStore from '@/store/common-modal-store';

import { DATA_CY_SEARCH_INPUT } from '@/constant/cypress';
import localization, { pageLevelLocalization } from '@/constant/localization';

export default function SearchInput() {
  // const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  // const containerRef = useRef(null);
  // const inputRef = useRef<HTMLInputElement>(null);
  // const { data } = useGetSearchSuggestion();
  // const isSmallScreen = useClientSmallScreen();
  const { setShow } = useCommonModalStore();
  // const [value, setValue] = useState('');
  // const { data: searchResult, refetch } = useGetSearch({
  //   params: {
  //     find: value,
  //     with_demo: true,
  //   },
  //   enabled: true,
  // });
  // const { params, setParams } = useSearchStore();

  // const debouncedRefetch = useCallback(
  //   debounce(() => {
  //     refetch();
  //     setParams(value);
  //   }, 1000),
  //   [value, refetch, setParams],
  // );

  // useEffect(() => {
  // setMobile(isSmallScreen);
  // if (showSearchDropDown && value) {
  //   debouncedRefetch();
  // }
  // }, [isSmallScreen, value, debouncedRefetch]);

  // useEffect(() => {
  //   if (value === '') {
  //     setParams('');
  //   }
  // }, [value, setParams]);

  // useOutsideAlerter(containerRef, () => setShowSearchDropDown(false));

  // const scrollToInput = () => {
  //   if (inputRef.current) {
  //     const inputPosition = inputRef.current.getBoundingClientRect().top + window.scrollY;
  //     window.scrollTo({
  //       top: inputPosition - 50,
  //       behavior: 'smooth',
  //     });
  //   }
  // };

  const onFocusHandler = () => {
    // if (mobile) {
    //   setShowSearchDropDown(true);
    //   setTimeout(scrollToInput, 300);
    // } else {
    openSearchModal();
    // }
  };

  const openSearchModal = () => {
    setShow(true, {
      Body() {
        return <SearchModal />;
      },
      DialogPanelProps: { className: 'w-full max-w-[680px] rounded-md !p-0' },
    });
  };

  return (
    <Container className='relative max-w-md pt-10'>
      <Container
      // ref={containerRef}
      // className={cn(
      //   showSearchDropDown &&
      //     'absolute left-0 top-10 z-20 w-full bg-white p-4 shadow-[0px_5px_25px_0px_rgba(126,137,155,0.12)] md:p-0',
      // )}
      >
        <Input
          data-cy={DATA_CY_SEARCH_INPUT}
          // ref={inputRef}
          className='z-30 border-0 px-4 py-1 placeholder:text-[#5B5C60]'
          size={SIZE_ENUM.MD}
          onClick={onFocusHandler}
          placeholder={pageLevelLocalization.home.searchPlaceHolder}
          // value={value}
          // onChange={(e) => isSmallScreen && setValue(e.target.value)}
          containerClassName='border-0 bg-white h-[60px] w-full max-w-[400px] md:max-w-[465px] shadow-[0px_5px_25px_0_rgba(126,137,155,0.12)]'
          Icon={() => (
            <Button
              Icon={<CiSearch height={18} width={18} strokeWidth={1.5} />}
              aria-label='Search'
              variant={VARIANT_ENUM.DARK_ORANGE}
              className='rounded-md'
              size={SIZE_ENUM.MD}
              onClick={openSearchModal}
            >
              {localization.search}
            </Button>
          )}
        />
        {/*{showSearchDropDown &&*/}
        {/*  !!data &&*/}
        {/*  (value && searchResult && params ? (*/}
        {/*    <Result demos={searchResult.demos} products={searchResult.products} />*/}
        {/*  ) : (*/}
        {/*    <>*/}
        {/*      <Suggests data={data?.suggestions} />*/}
        {/*      <PreviousSearch data={data?.previousSearch} />{' '}*/}
        {/*    </>*/}
        {/*  ))}*/}
      </Container>
    </Container>
  );
}
