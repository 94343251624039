'use client';

import Container from '@/components/@base/container';
import Background from '@/components/app/home/components/best-seller/background';
import BecomeSeller from '@/components/app/home/components/best-seller/become-seller';
import IconicProducts from '@/components/app/home/components/best-seller/iconic-products';
import Info from '@/components/app/home/components/best-seller/info';
import { useGetBestSeller } from '@/components/app/home/services/hooks';
import Medals from '@/components/shared/components/medals';

import { DATA_CY_BEST_SELLER } from '@/constant/cypress';

export default function BestSeller() {
  const { data } = useGetBestSeller();
  if (!data) {
    return null;
  }
  return (
    <Container
      data-cy={DATA_CY_BEST_SELLER}
      center
      className='z-10 mt-[34px] max-w-[1279px] flex-col justify-start rounded-xl p-4 md:mt-[64px] md:flex-row md:p-0 md:pb-[30px]'
    >
      <Container className='h-1 w-5/6 rounded-tl-xl rounded-tr-xl bg-[linear-gradient(79.75deg,_#FEA000_6.08%,_#FEBE00_100.67%)] md:h-[304px] md:w-2 md:rounded-br-xl md:rounded-tl-none' />
      <Container className='relative flex w-full flex-col justify-between rounded-xl bg-white p-[5px] shadow-[0px_5px_25px_0px_rgba(126,137,155,0.05)] md:max-w-[calc(100%_-_8px)] md:flex-row md:p-0'>
        <Background />
        <Container
          center
          className='flex-col items-stretch px-5 py-7 md:w-[calc(100%-400px)] md:gap-8 md:pb-5 md:pr-9 md:pt-9'
        >
          <Container className='flex flex-col gap-7 md:flex-row'>
            <Info {...data} />
            <Medals medals={data.medals} />
          </Container>
          <IconicProducts products={data?.products} />
        </Container>
        <BecomeSeller />
      </Container>
    </Container>
  );
}
