'use client';
import Link from 'next/link';
import { Autoplay, EffectCreative } from 'swiper/modules';
import 'swiper/css';

import BaseCarousel, { CarouselItem } from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import { CONTAINER_TAG_TYPE } from '@/components/@base/container/type';
import XImage from '@/components/@base/x-image';
import Props from '@/components/app/home/components/hero/rotating-banner-card/carousel/props';

import imagesList from '@/constant/images-list';

export default function Carousel({ slides }: Props) {
  return (
    <Container center>
      <BaseCarousel
        effect='creative'
        creativeEffect={{
          prev: {
            shadow: true,
            translate: ['100%', '20px', 0],
            rotate: [0, 0, 6],
          },
          next: {
            shadow: true,
            translate: ['-100%', '20px', 0],
            rotate: [0, 0, -6],
          },
        }}
        modules={[EffectCreative, Autoplay]}
        slidesPerView={slides.length}
        centeredSlides
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop
        className='min-w-[350px] !overflow-visible'
      >
        {slides?.map((item) => {
          return (
            <CarouselItem key={item.id} className='relative'>
              <Link href={`/web/${item?.slug}`} prefetch={false}>
                <Container className='relative h-[171px] w-[237px]'>
                  <XImage
                    src={item?.cover?.publicLink}
                    alt={item?.cover?.title}
                    fill
                    className='rounded-[15px] object-cover object-top'
                  />
                  <Container
                    type={CONTAINER_TAG_TYPE.SPAN}
                    center
                    className='absolute bottom-[-20px] z-30 w-full'
                  >
                    <XImage
                      src={item?.vendorIcon?.publicLink || imagesList.icons.vendorIcon}
                      alt={item?.vendorIcon?.title}
                      width={45}
                      height={45}
                      className='rounded-[17px] border-2 border-solid border-[#232532]'
                    />
                  </Container>
                </Container>
              </Link>
            </CarouselItem>
          );
        })}
      </BaseCarousel>
    </Container>
  );
}
