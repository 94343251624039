import Link from 'next/link';
import { LuClock3 } from 'react-icons/lu';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Card, { CardContent, CardMedia } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import { TEXT_TYPE } from '@/components/@base/typography/text/type';
import Props from '@/components/app/home/components/blogs/blog/type';

import { pageLevelLocalization } from '@/constant/localization';

const { home: homeLocalization } = pageLevelLocalization;

export default function Blog({ cover, title, url, studyTime }: Props) {
  return (
    <Card className='group h-[302px] w-[302px] rounded-xl bg-white'>
      <Link href={url} prefetch={false}>
        <CardContent>
          <CardMedia
            src={cover.publicLink}
            alt={cover.title}
            className='w-full rounded-xl'
            fill
          />
          <Container
            center
            className='absolute bottom-4 left-4 h-[114px] w-[calc(100%_-_32px)] flex-col items-start justify-between rounded-xl bg-white px-5 pb-3 pt-5'
          >
            <Text
              size={SIZE_ENUM.MD}
              className='line-clamp-2 text-[#5F5F5F] group-hover:text-[#ff9606]'
            >
              {title}
            </Text>
            <Container center className='flex items-center gap-2'>
              <LuClock3 color='#878F9B' />
              <Text type={TEXT_TYPE.SPAN} size={SIZE_ENUM.XS} className='pt-1 text-[#878F9B]'>
                {studyTime || 0}
                {studyTime && homeLocalization.studyTime}
              </Text>
            </Container>
          </Container>
        </CardContent>
      </Link>
    </Card>
  );
}
