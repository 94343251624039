'use client';

import { useEffect, useRef, useState } from 'react';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { Swiper as SwiperType } from 'swiper';

import cn from '@/lib/clsxm';

import Carousel from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import { Props } from '@/components/app/home/components/@shared/carousel/type';

export default function ProductsCarousel({
  children,
  className,
  carouselOptions,
  carouselClassName,
  arrowClassName,
}: Props) {
  const nextButtonRef = useRef<HTMLDivElement | null>(null);
  const prevButtonRef = useRef<HTMLDivElement | null>(null);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    if (
      swiperInstance &&
      nextButtonRef.current &&
      prevButtonRef.current &&
      swiperInstance.params.navigation &&
      typeof swiperInstance.params.navigation !== 'boolean'
    ) {
      // update navigation parameters
      swiperInstance.params.navigation.prevEl = prevButtonRef.current;
      swiperInstance.params.navigation.nextEl = nextButtonRef.current;

      // re-initialize navigation
      swiperInstance.navigation.destroy();
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();

      if (!showBtn) {
        setShowBtn(true);
      }
    }
  }, [swiperInstance, showBtn]);

  return (
    <Container className={cn('relative w-full [&_.swiper-button-disabled]:hidden', className)}>
      <Carousel
        className={cn(
          '!py-4',
          !swiperInstance && '[&_.swiper-wrapper]:gap-5',
          carouselClassName,
        )}
        spaceBetween={20}
        slidesPerView='auto'
        navigation={{ enabled: true }}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        {...carouselOptions}
      >
        {children}
      </Carousel>

      {/* Navigation Buttons */}
      <Container
        ref={nextButtonRef}
        className={cn(
          'absolute -left-[25px] top-1/2 z-10 hidden h-[53px] w-[53px] -translate-y-1/2 transform cursor-pointer rounded-full bg-[#FFFFFFB2] p-1.5 md:block',
          arrowClassName,
        )}
        center
      >
        <Container
          center
          className='h-full w-full rounded-full bg-[#FFFFFF] p-2 text-[#7E899B] shadow-[0px_5px_15px_0px_#00000026] transition duration-300  hover:bg-[#E5E8EB]'
        >
          <SlArrowLeft />
        </Container>
      </Container>
      <Container
        ref={prevButtonRef}
        className={cn(
          !showBtn && '!hidden',
          'absolute -right-[25px] top-1/2 z-10 hidden h-[53px] w-[53px] -translate-y-1/2 transform cursor-pointer rounded-full bg-[#FFFFFFB2] p-1.5 md:block',
          arrowClassName,
        )}
        center
      >
        <Container
          center
          className='h-full w-full rounded-full bg-[#FFFFFF] p-2 text-[#7E899B] shadow-[0px_5px_15px_0px_#00000026]  transition duration-300 hover:bg-[#E5E8EB]'
        >
          <SlArrowRight />
        </Container>
      </Container>
    </Container>
  );
}
