import cn from '@/lib/clsxm';

import { CardMedia } from '@/components/@base/card';
import Container from '@/components/@base/container';
import Props from '@/components/shared/components/cards/demo/media/type';

import imagesList from '@/constant/images-list';

export default function Media({ isHovered, cover }: Props) {
  return (
    <Container className='relative h-full w-full overflow-hidden'>
      <CardMedia
        className={cn(
          'left-0 top-0 h-full w-full object-cover object-bottom transition-all duration-[2000ms] ease-in-out md:object-top',
          isHovered && 'md:object-bottom',
        )}
        fill
        src={cover?.publicLink || imagesList.images.demoCover}
        alt={cover?.title}
      />
    </Container>
  );
}
