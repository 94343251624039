'use client';
import { useEffect, useState } from 'react';
import ProductsCarousel from 'src/components/app/home/components/@shared/carousel';

import { useClientSmallScreen } from '@/lib/user-agent/client';

import { CarouselItem } from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import Props from '@/components/app/home/components/best-seller/iconic-products/type';
import { CubicProductCard, IconicProductCard } from '@/components/shared/components/cards';

import { DATA_CY_BEST_SELLER_PRODUCTS } from '@/constant/cypress';

export default function IconicProducts({ products }: Props) {
  const isSmallScreen = useClientSmallScreen();
  const [rowMode, setRowMode] = useState(false);

  useEffect(() => {
    setRowMode(!isSmallScreen);
  }, [products, isSmallScreen]);

  if (rowMode) {
    return (
      <ProductsCarousel className='pt-2'>
        {products?.map((item) => (
          <CarouselItem key={item.id} className='!w-[195px]'>
            <CubicProductCard key={item.id} {...item} />
          </CarouselItem>
        ))}
      </ProductsCarousel>
    );
  }

  return (
    <Container className='flex flex-col gap-3 pt-6 md:flex-row'>
      {products?.map((item) => {
        return (
          <IconicProductCard
            data-cy={DATA_CY_BEST_SELLER_PRODUCTS}
            data={item}
            key={item.id}
          />
        );
      })}
    </Container>
  );
}
