import { useEffect, useState } from 'react';

import TabGroup, { Tab, Tabs } from '@/components/@base/tab';
import { useGetDemoList } from '@/components/app/home/services/hooks';

import { DEMO_FILTERS } from '@/constant/iterable-items';

export default function Filters({ onTabChange }) {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { refetch } = useGetDemoList({
    params: { categories: [DEMO_FILTERS?.[selectedTab]?.value] },

    enabled: true,
  });
  useEffect(() => {
    if (typeof selectedTab !== 'undefined') {
      refetch();
    }
  }, [selectedTab]);

  return (
    <TabGroup
      onChange={(index) => {
        setSelectedTab(index);
        onTabChange(index);
      }}
    >
      <Tabs className='flex items-center gap-[10px] py-3'>
        {DEMO_FILTERS.map((item) => (
          <Tab className='h-9 rounded-md bg-[#F7F8F9] px-[25px] py-1 text-[13px] text-[#878F9B] outline-none transition duration-300 hover:bg-[#878F9B] hover:text-white ui-selected:bg-[rgba(254,160,0,0.1)] ui-selected:text-[#FF9606]'>
            {item.title}
          </Tab>
        ))}
      </Tabs>
    </TabGroup>
  );
}
