import { useClientSmallScreen } from '@/lib/user-agent/client';

import Props from '@/components/app/home/components/courses/course/type';
import { Banner } from '@/components/shared/components/cards';
import { BANNER_TYPE } from '@/components/shared/components/cards/banner/type';

export default function Course({sectionId, image, title, icon, url }: Props) {
  const isMobile = useClientSmallScreen();
  return (
    <Banner
      id={sectionId}
      image={image.publicLink}
      type={BANNER_TYPE.IMAGE}
      link={url}
      title={title}
      target="_blank"
      rel="nofollow"
      className='rounded-xl border-[3px] border-solid border-white'
      width={isMobile ? 392 : 410}
      height={isMobile ? 147 : 192}
    />
  );
}
