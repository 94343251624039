'use client';

import Container from '@/components/@base/container';
import Top from '@/components/app/home/components/best-products/top';
import { useGetBestProductList } from '@/components/app/home/services/hooks';

import { DATA_CY_BEST_PRODUCTS } from '@/constant/cypress';

export default function BestProducts() {
  const { data } = useGetBestProductList();
  if (!data) {
    return null;
  }
  return (
    <Container
      data-cy={DATA_CY_BEST_PRODUCTS}
      center
      className='max-w-[1279px] flex-col items-start gap-4 p-4 md:flex-row md:p-0 [&>*]:w-full'
    >
      {data?.map((item) => {
        return (
          <Top
            key={item.id}
            title={item.title}
            icon={item.icon}
            url={item.url}
            btnTitle={item.btnTitle}
            bestProduct={item.bestProduct}
            products={item.products}
          />
        );
      })}
    </Container>
  );
}
