import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import Props from '@/components/shared/components/icon/rounded/type';

export default function RoundedIcon({ isDark, icon, className, iconClassName }: Props) {
  return (
    <Container
      center
      className={cn(
        'h-11 w-11 rounded-full',
        isDark
          ? 'bg-[linear-gradient(247.65deg,_#FFC107_-35.57%,_#FF9737_100%)] shadow-[0px_5px_20px_0px_rgba(255,150,6,0.21)]'
          : 'bg-white shadow-[0px_5px_20px_0px_rgba(219,146,78,0.3)]',
        className,
      )}
    >
      <Container
        className={cn(
          'h-10 w-10 rounded-full',
          isDark
            ? 'bg-[linear-gradient(180deg,_#3A3F53_0%,_#1B2030_100%)]'
            : 'bg-[linear-gradient(247.65deg,_#FFC107_-35.57%,_#FF9737_100%)]',
          iconClassName,
        )}
        center
      >
        {icon}
      </Container>
    </Container>
  );
}
