'use client';
import { useState } from 'react';

import cn from '@/lib/clsxm';
import handleGA4Event, { eventTracker } from '@/lib/service-workers';

import Card, { CardContent } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { useGetProfile } from '@/components/layout/services/hooks';
import HoverMode from '@/components/shared/components/cards/demo/content/hover-mode';
import StaticMode from '@/components/shared/components/cards/demo/content/static-mode';
import Media from '@/components/shared/components/cards/demo/media';
import Props from '@/components/shared/components/cards/demo/type';

export default function Demo({ data, className }: Props) {
  const {
    title,
    demoName,
    price,
    slug,
    demoCover,
    demoId,
    icon,
    id,
    lastPrice,
    discount,
    saleEndedAt,
    sellable,
    categories,
    store,
  } = data;
  const [isHovered, setIsHovered] = useState(false);
  const { profile } = useGetProfile();
  const eventHandler = () => {
    eventTracker(profile?.mobile, 'product_click', new Date().toISOString(), {
      url: window.location?.href,
      category: categories?.map((item) => ({
        $oid: item?.id,
      })),
      vendor: store?.id,
      price: price,
    });
    handleGA4Event('select_item', [
      {
        item_id: id,
        item_name: title,
        price: price,
        index: 0,
        quantity: 1,
        affiliation: store?.slug,
        discount: discount,
      },
    ]);
  };
  return (
    <Container
      className={cn(
        'relative aspect-[0.74] h-auto w-full max-w-[400px] md:aspect-auto md:h-[342px]',
        className,
      )}
      onClick={eventHandler}
    >
      <Card
        className={cn(
          'absolute left-0 top-0 aspect-[0.74] h-auto w-full rounded-md shadow-[0px_10px_35px_0px_rgba(0,0,0,0.06)] transition-all duration-700 ease-in-out md:z-10' +
            ' md:h-full md:w-full',
          isHovered &&
            'md:left-[-50px] md:top-[-50px] md:z-50 md:h-[482px] md:w-[355px] md:shadow-[0px_10px_35px_0px_rgba(0,0,0,0.06)]',
        )}
        noShadow
        noBorder
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardContent className='relative h-full w-full p-0'>
          <Media isHovered={isHovered} cover={demoCover} />
          <StaticMode
            isHovered={isHovered}
            title={demoName || title}
            icon={icon}
            productTitle={title}
            price={price}
            lastPrice={lastPrice}
            discount={discount}
            saleEndedAt={saleEndedAt}
            sellable={sellable}
            slug={slug}
            demoId={demoId}
          />
          <HoverMode
            id={id}
            demoId={demoId}
            isHovered={isHovered}
            title={demoName || title}
            icon={icon}
            productTitle={title}
            price={price}
            lastPrice={lastPrice}
            discount={discount}
            saleEndedAt={saleEndedAt}
            slug={slug}
            sellable={sellable}
          />
        </CardContent>
      </Card>
    </Container>
  );
}
