'use client';

import { AiOutlinePercentage } from 'react-icons/ai';
import { RxCalendar, RxUpdate } from 'react-icons/rx';

import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import { CarouselItem } from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import { HEADING_TYPE } from '@/components/@base/typography/heading/type';
import ProductsCarousel from '@/components/app/home/components/@shared/carousel';
import SeeAllButton from '@/components/app/home/components/@shared/see-all-button';
import Props from '@/components/app/home/components/products/type';
import {
  useGetNewestIranianProductList,
  useGetNewestProductList,
  useGetOnSaleProductList,
} from '@/components/app/home/services/hooks';
import { CubicProductCard, ProductCard } from '@/components/shared/components/cards';
import RoundedIcon from '@/components/shared/components/icon/rounded';
import IconTextCombo from '@/components/shared/components/icon-text-combo';

import { DATA_CY_PRODUCT_CARD, DATA_CY_PRODUCTS } from '@/constant/cypress';
import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import localization from '@/constant/localization';

export default function Products({ iconic, onSale, listPath = '/' }: Props) {
  const { data: newestProduct } = useGetNewestProductList();
  const { data: newestIranianProduct } = useGetNewestIranianProductList({
    params: {
      categories: ['5dcaacbfeaec37018b508a3d'],
      sort_by: 'last_updated',
      iranian: true,
      'per-page': 10,
    },
  });
  const { data: onSaleProducts } = useGetOnSaleProductList({
    params: {
      sort_by: 'highest_discount',
      sellable: true,
      hasDiscount: true,
      'per-page': 10,
    },
  });

  const renderProducts = onSale
    ? onSaleProducts
    : iconic
      ? newestIranianProduct
      : newestProduct;

  if (!renderProducts || renderProducts.length < 6) {
    return null;
  }

  return (
    <Container
      data-cy={DATA_CY_PRODUCTS}
      className='flex max-w-[1279px] flex-col gap-1 md:pt-10 [&>*]:w-full'
    >
      <Container center className='p-4 md:p-0'>
        <IconTextCombo
          textSize={SIZE_ENUM.XL}
          Icon={
            <RoundedIcon
              icon={
                onSale ? (
                  <AiOutlinePercentage color={ICON_COLOR.white} size={ICON_SIZE.sm} />
                ) : iconic ? (
                  <RxUpdate color={ICON_COLOR.white} size={ICON_SIZE.sm} />
                ) : (
                  <RxCalendar color={ICON_COLOR.white} size={ICON_SIZE.sm} />
                )
              }
            />
          }
          headingType={HEADING_TYPE.H2}
          title={
            onSale
              ? localization.onSaleProducts
              : iconic
                ? localization.lastUpdatedIranianProducts
                : localization.newestProducts
          }
          textClassName='font-bold text-xl md:text-[21px] text-[#454545]'
        />
        <SeeAllButton url={listPath} theme='light' className='!h-[45px]'/>
      </Container>
      <ProductsCarousel className='pr-4 md:px-4 md:pr-0 '>
        {renderProducts.map((item) => {
          return (
            <CarouselItem
              key={item.id}
              className={cn(iconic || onSale ? '!w-[195px]' : '!w-[304px]')}
            >
              {iconic || onSale ? (
                <CubicProductCard {...item} />
              ) : (
                <ProductCard data={item} data-cy={DATA_CY_PRODUCT_CARD} />
              )}
            </CarouselItem>
          );
        })}
      </ProductsCarousel>
    </Container>
  );
}
