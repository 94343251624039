import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Card, { CardAction, CardContent, CardMedia } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import Actions from '@/components/app/home/components/best-seller/become-seller/actions';

import assets from '@/constant/images-list';
import imagesList from '@/constant/images-list';
import localization from '@/constant/localization';

export default function BecomeSeller() {
  return (
    <Card className='relative items-center rounded-br-none rounded-tr-none border-2 border-white px-5 py-7 md:w-[354px] md:border-none md:p-10'>
      <XImage src={imagesList.backgrounds.becomeSellerBg} alt={localization.zhaket} fill />
      <CardContent className='relative w-full p-0'>
        <Container className='relative mx-auto h-[105px] w-full md:w-[270px]'>
          <CardMedia
            src={imagesList.images.becomeSellerBanner}
            alt={localization.zhaket}
            fill
            className='rounded-xl'
          />
        </Container>

        <XImage
          src={assets.icons.becomeSeller}
          alt='become-seller'
          className='absolute left-[40%] top-[65px] md:left-[38%] md:top-[60px]'
        />
        <Text center size={SIZE_ENUM.LG} bold className='pt-16 text-[#5B5C60]'>
          {localization.lookForIncome}
        </Text>
        <Text center className='pb-4 text-[#76767C] md:pb-0' medium>
          {localization.cooperateWithUs}
        </Text>
      </CardContent>
      <CardAction center className='z-20 w-full gap-4 p-0'>
        <Actions />
      </CardAction>
    </Card>
  );
}
