import axios from '@/lib/axios-instance';
import { APIUrlGenerator } from '@/lib/helper';

import {
  DemoListType,
  GetAdsBannerType,
  GetTopSaleListType,
  NewestProductsType,
} from '@/components/app/home/services/types';

import { API_ROUTES } from '@/constant/routes';
import AdsBannerModel from '@/models/ads-banner.model';
import BannerModel from '@/models/banner.model';
import BestOfCategoryModel from '@/models/best-of-category.model';
import BestProductModel from '@/models/best-product.model';
import BestSellerModel from '@/models/best-seller.model';
import BlogModel from '@/models/blog.model';
import BundleModel from '@/models/bundle.model';
import ProductModel from '@/models/product.model';
import StoryModel from '@/models/story.model';

// export async function getServiceList(params?: GetServiceListType) {
//   const { data }: { data: ServiceModel[] } = await axios.get(
//     APIUrlGenerator(API_ROUTES.GET_SERVICES, params),
//   );
//   return data;
// }

export async function getStoryList() {
  const {
    data: { payload },
  }: { data: { payload: StoryModel[] } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_STORY_LIST),
  );
  return payload;
}

export async function getAdsBanner({ type }: GetAdsBannerType) {
  const {
    data: { payload },
  }: { data: { payload: AdsBannerModel } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_ADS_BANNER(type)),
  );
  return payload;
}

export async function getBundleList() {
  const {
    data: { payload },
  }: { data: { payload: BundleModel[] } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_BUNDLE_LIST, { limit: 8 }),
  );
  return payload;
}

export async function getNewestProductList(params?: NewestProductsType) {
  const {
    data: { payload },
  }: { data: { payload: ProductModel[] } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_NEWEST_PRODUCT_LIST, params),
  );
  return payload;
}

export async function getBannerList({ type }: GetAdsBannerType) {
  const {
    data: { payload },
  }: { data: { payload: BannerModel[] } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_ADS_BANNER(type)),
  );
  return payload;
}

export async function getBestSeller() {
  const {
    data: { payload },
  }: { data: { payload: BestSellerModel } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_BEST_SELLER),
  );
  return payload;
}

export async function getBestProductList() {
  const {
    data: { payload },
  }: { data: { payload: BestProductModel[] } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_BEST_PRODUCT_LIST),
  );
  return payload;
}

export async function getBlogList() {
  const {
    data: { payload },
  }: { data: { payload: BlogModel[] } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_BLOG_LIST),
  );
  return payload;
}

export async function getBestOfCateList() {
  const {
    data: { payload },
  }: { data: { payload: BestOfCategoryModel } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_BEST_OF_CATE_LIST),
  );
  return payload;
}

export async function getIRBestList(params?: GetTopSaleListType) {
  const {
    data: { payload },
  }: { data: { payload: ProductModel[] } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_IR_BEST_LIST, params),
  );
  return payload;
}

export async function getDemoList(params?: DemoListType['params']) {
  const {
    data: { payload },
  }: { data: { payload: ProductModel[] } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_DEMO_LIST, params),
  );
  return payload;
}
