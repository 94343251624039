'use client';

import { useState } from 'react';
import { MdOutlineWebStories } from 'react-icons/md';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import { CarouselItem } from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import { HEADING_TYPE } from '@/components/@base/typography/heading/type';
import ProductsCarousel from '@/components/app/home/components/@shared/carousel';
import SeeAllButton from '@/components/app/home/components/@shared/see-all-button';
import Filters from '@/components/app/home/components/demos/filters';
import { useGetDemoList } from '@/components/app/home/services/hooks';
import Demo from '@/components/shared/components/cards/demo';
import Loading from '@/components/shared/components/cards/product/loading';
import RoundedIcon from '@/components/shared/components/icon/rounded';
import IconTextCombo from '@/components/shared/components/icon-text-combo';
import { loadingGenerator } from '@/components/shared/helpers';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import { DEMO_FILTERS } from '@/constant/iterable-items';
import localization from '@/constant/localization';

export default function Demos() {
  const { data, isLoading, isRefetching } = useGetDemoList();
  const isDataLoading = isLoading || isRefetching;
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Container className='flex max-w-[1279px] flex-col gap-1 md:pt-10 [&>*]:w-full'>
      <Container className='p-4 md:p-0'>
        <Container center>
          <IconTextCombo
            textSize={SIZE_ENUM.XL}
            Icon={
              <RoundedIcon
                icon={<MdOutlineWebStories color={ICON_COLOR.white} size={ICON_SIZE.sm} />}
              />
            }
            headingType={HEADING_TYPE.H2}
            title={localization.mostSalesDemos}
            textClassName='font-bold text-xl md:text-[21px] text-[#454545]'
          />
          <SeeAllButton
            url={DEMO_FILTERS[selectedTab].listPath}
            theme='light'
            className='!h-[45px]'
          />
        </Container>
        <Filters onTabChange={setSelectedTab} />
      </Container>

      <ProductsCarousel
        className='pr-4 md:px-4 md:pr-0'
        carouselOptions={{ loop: false, centeredSlides: false }}
        carouselClassName='!overflow-visible !overflow-x-clip'
      >
        {isDataLoading
          ? loadingGenerator(4, () => <Loading />).map((item, index) => (
              <CarouselItem key={index} className='!w-[304px]'>
                {item}
              </CarouselItem>
            ))
          : Array.isArray(data) &&
            data?.map((item) => (
              <CarouselItem key={item.id} className='!w-[304px] hover:z-40'>
                <Demo key={item.id} data={item} />
              </CarouselItem>
            ))}
      </ProductsCarousel>
    </Container>
  );
}
