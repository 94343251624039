import { Tab as HeadlessUiTab } from '@headlessui/react';
import type { ElementType } from 'react';

import cn from '@/lib/clsxm';

import Props from '@/components/@base/tab/tab/type';

const DEFAULT_TAB_TAG = 'button';
export default function Tab<TTag extends ElementType = typeof DEFAULT_TAB_TAG>({
  children,
  className,
  ...props
}: Props<TTag>) {
  return (
    <HeadlessUiTab {...props} className={cn('outline-none', className)}>
      {children}
    </HeadlessUiTab>
  );
}
