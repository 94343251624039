import { useQuery } from '@tanstack/react-query';

import {
  getAdsBanner,
  getBannerList,
  getBestOfCateList,
  getBestProductList,
  getBestSeller,
  getBlogList,
  getBundleList,
  getDemoList,
  getIRBestList,
  getNewestProductList,
  getStoryList,
} from '@/components/app/home/services';
import { GetAdsBannerType, GetTopSaleListType } from '@/components/app/home/services/types';
import { getIconicProducts } from '@/components/shared/services';
import { SearchType } from '@/components/shared/services/types';

import {
  GET_ADS_BANNER,
  GET_BEST_OF_CATE_LIST,
  GET_BEST_PRODUCT_LIST,
  GET_BEST_SELLER,
  GET_BLOG_LIST,
  GET_BUNDLE_LIST,
  GET_COURSE_LIST,
  GET_DEMO_LIST,
  GET_IR_BEST_LIST,
  GET_NEWEST_IRANIAN_PRODUCT_LIST,
  GET_NEWEST_PRODUCT_LIST,
  GET_ON_SALE_PRODUCT_LIST,
  GET_PRODUCTS_BANNER_LIST,
  GET_STORY_LIST,
} from '@/constant/query-key';
import props from '@/components/app/home/components/hero/rotating-banner-card/carousel/props';
import { DemoListType } from '@/components/app/home/services/types';
// export function useGetServiceList(params?: GetServiceListType) {
//   return useQuery({
//     queryFn: () => getServiceList(params),
//     queryKey: [GET_SERVICE_LIST],
//   });
// }

export function useGetStoryList() {
  return useQuery({
    queryFn: () => getStoryList(),
    queryKey: [GET_STORY_LIST],
  });
}

export function useGetAdsBanner(params: GetAdsBannerType) {
  return useQuery({
    queryFn: () => getAdsBanner(params),
    queryKey: [GET_ADS_BANNER],
  });
}

export function useGetBundleList() {
  return useQuery({
    queryFn: () => getBundleList(),
    queryKey: [GET_BUNDLE_LIST],
  });
}

export function useGetNewestProductList() {
  return useQuery({
    queryFn: () => getNewestProductList(),
    queryKey: [GET_NEWEST_PRODUCT_LIST],
  });
}

export function useGetNewestIranianProductList(params?: SearchType) {
  return useQuery({
    queryFn: () => getIconicProducts(params),
    queryKey: [GET_NEWEST_IRANIAN_PRODUCT_LIST],
  });
}

export function useGetOnSaleProductList(params?: SearchType) {
  return useQuery({
    queryFn: () => getIconicProducts(params),
    queryKey: [GET_ON_SALE_PRODUCT_LIST],
  });
}

export function useGetDemoList(props?:  DemoListType) {
  return useQuery({
    queryFn: () => getDemoList(props?.params),
    queryKey: [GET_DEMO_LIST],
    enabled: props?.enabled || false,
  });
}

export function useGetProductsBannerList(params: GetAdsBannerType) {
  return useQuery({
    queryFn: () => getBannerList(params),
    queryKey: [GET_PRODUCTS_BANNER_LIST],
  });
}

export function useGetBestSeller() {
  return useQuery({
    queryFn: () => getBestSeller(),
    queryKey: [GET_BEST_SELLER],
  });
}

export function useGetBestProductList() {
  return useQuery({
    queryFn: () => getBestProductList(),
    queryKey: [GET_BEST_PRODUCT_LIST],
  });
}

export function useGetBlogList() {
  return useQuery({
    queryFn: () => getBlogList(),
    queryKey: [GET_BLOG_LIST],
  });
}

export function useGetCourseList(params: GetAdsBannerType) {
  return useQuery({
    queryFn: () => getBannerList(params),
    queryKey: [GET_COURSE_LIST],
  });
}

export function useGetBestOfCateList() {
  return useQuery({
    queryFn: () => getBestOfCateList(),
    queryKey: [GET_BEST_OF_CATE_LIST],
  });
}

export function useGetIRBestList(params?: GetTopSaleListType) {
  return useQuery({
    queryFn: () => getIRBestList(params),
    queryKey: [GET_IR_BEST_LIST],
  });
}
