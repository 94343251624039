import cn from '@/lib/clsxm';
import { priceFormatter } from '@/lib/helper';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import { TEXT_TYPE } from '@/components/@base/typography/text/type';
import XImage from '@/components/@base/x-image';
import Props from '@/components/shared/components/cards/demo/content/static-mode/type';
import IconLinkCombo from "@/components/shared/components/icon-link-combo";
import PriceView from '@/components/shared/components/price-view';

import localization from '@/constant/localization';

export default function StaticMode({
  isHovered,
  title,
  icon,
  productTitle,
  price,
  lastPrice,
  discount,
  saleEndedAt,
  sellable,
  slug,
  demoId,
}: Props) {
  return (
    <Container
      center
      className={cn(
        'absolute bottom-0 left-0 right-0 hidden h-[50%] w-full flex-col items-start justify-end gap-2 bg-[linear-gradient(180deg,rgba(0,0,0,0)_0%,rgba(2,2,3,0.657)_53.12%,rgba(4,4,5,0.9)_100%)] px-5 py-7 md:flex [&>*]:w-full',
        isHovered && 'shadow-none md:hidden',
      )}
    >
      <a
        // size={SIZE_ENUM.MD}
        className='line-clamp-1 text-[#F4F5F6] text-md' href={`/web/${slug}/demo?demo_id=${demoId}`}>
        {title}
      </a>
      <Container center className='w-full justify-between'>
        <IconLinkCombo
          Icon={<XImage src={icon?.publicLink} width={22} height={22} alt={icon?.title} />}
          textSize={SIZE_ENUM.XS}
          textClassName='line-clamp-1 w-full max-w-[120px] text-[#F4F5F6]'
          title={productTitle}
          link={`/web/${slug}`}
          target='_blank'
        />
        {/*<Text bold className='text-[#E5E8EB]' size={SIZE_ENUM.MD}>*/}
        {/*  {priceFormatter(price)}*/}
        {/*  <Text type={TEXT_TYPE.SPAN} className='pr-1 text-[11px] text-[#C1C3C8]'>*/}
        {/*    {localization.IRCurrency}*/}
        {/*  </Text>*/}
        {/*</Text>*/}
        <PriceView
          isDark={true}
          sellable={sellable}
          price={price}
          lastPrice={lastPrice}
          discount={discount}
          saleEndedAt={saleEndedAt}
          priceClassName='text-[#E5E8EB] font-bold text-md'
          currencyClassName='text-[11px] text-[#C1C3C8]'
        />
      </Container>
    </Container>
  );
}
