'use client';

import { useClientSmallScreen } from '@/lib/user-agent/client';

import Container from '@/components/@base/container';
import { useGetProductsBannerList } from '@/components/app/home/services/hooks';
import { ADS_BANNER_TYPE } from '@/components/app/home/services/types';
import { Banner } from '@/components/shared/components/cards';
import { BANNER_TYPE } from '@/components/shared/components/cards/banner/type';

import { DATA_CY_PRODUCT_BANNER } from '@/constant/cypress';

export default function ProductBanner() {
  const { data } = useGetProductsBannerList({ type: ADS_BANNER_TYPE.TWO_COLUMN });
  const isMobile = useClientSmallScreen();
  if (!data) {
    return null;
  }
  return (
    <Container
      data-cy={DATA_CY_PRODUCT_BANNER}
      center
      className='w-full max-w-[1279px] flex-col gap-4 p-4 pb-8 pt-0 md:flex-row md:p-0 md:pb-8 md:pt-16 [&>*]:w-full'
    >
      {data?.map((item,index) => {
        const bannerId= index === 0 ? "zhkmidads-ri" : "zhkmidads-le"
        return (
            <Banner
              id={bannerId}
              key={item.id}
              image={item?.image?.publicLink}
              type={BANNER_TYPE.IMAGE}
              title={item.title}
              link={item.url}
              width={isMobile ? 397 : 624}
              height={isMobile ? 235 : 283}
              className='max-h-[280px] rounded-xl shadow-[0px_5px_25px_0px_rgba(203,203,203,0.25)]'
            />
        );
      })}
    </Container>
  );
}
