'use client';

import Link from 'next/link';
import { BiSolidEdit } from 'react-icons/bi';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import { VARIANT_ENUM } from '@/components/@base/button/type';
import Carousel, { CarouselItem } from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import Blog from '@/components/app/home/components/blogs/blog';
import { useGetBlogList } from '@/components/app/home/services/hooks';
import RoundedIcon from '@/components/shared/components/icon/rounded';
import IconTextCombo from '@/components/shared/components/icon-text-combo';

import { DATA_CY_BLOGS, DATA_CY_BLOGS_SLIDER } from '@/constant/cypress';
import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import { pageLevelLocalization } from '@/constant/localization';

const { home } = pageLevelLocalization;

export default function Blogs() {
  const { data } = useGetBlogList();
  if (!data) {
    return null;
  }
  return (
    <Container
      data-cy={DATA_CY_BLOGS}
      center
      className='max-w-[1279px] flex-col gap-[38px] md:gap-11 md:pt-14 [&>*]:w-full'
    >
      <Container center className='justify-between px-4 md:p-0'>
        <IconTextCombo
          Icon={
            <RoundedIcon icon={<BiSolidEdit color={ICON_COLOR.white} size={ICON_SIZE.md} />} />
          }
          title={home.latestBlogs}
          textSize={SIZE_ENUM.XL}
          textClassName='md:text-[23px] text-[#454545]'
          wrapperClassName='max-w-max'
          // textType='h2'
        />
        <Link href='/blog' target='_blank' prefetch={false}>
          <Button
            className='h-[44px] w-[110px] font-bold text-[#5B5C60] md:h-[50px] md:w-[150px] md:text-[15px]'
            variant={VARIANT_ENUM.SOUL}
          >
            {home.viewBlogs}
          </Button>
        </Link>
      </Container>
      <Container className='pr-4 md:p-0'>
        <Carousel slidesPerView='auto' className='[&_.swiper-wrapper]:gap-[23px]'>
          {data?.map((item) => {
            return (
              <CarouselItem
                data-cy={DATA_CY_BLOGS_SLIDER}
                key={item.id}
                className='!w-[302px]'
              >
                <Blog {...item} />
              </CarouselItem>
            );
          })}
        </Carousel>
      </Container>
    </Container>
  );
}
