export type GetServiceListType = {
  count?: number;
  from?: number;
};
export type GetTopSaleListType = {
  category?: string;
  tag?: string;
  limit?: number;
  iranian?: boolean;
  time?: number;
};

export enum ADS_BANNER_TYPE {
  ONE_COLUMN = 'one_column',
  TWO_COLUMN = 'two_column',
  THREE_COLUMN = 'three_column',
}

export type GetAdsBannerType = {
  type: ADS_BANNER_TYPE;
};
export type NewestProductsType = {
  category?: string[];
  tag?: string[];
  limit?: number;
  sort_by?: string;
};

export type DemoListType = {
  params?: { categories?: string[]}  ;
  enabled?: boolean;
};
