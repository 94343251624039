'use client';
import Container from '@/components/@base/container';
import Product from '@/components/app/home/components/hero/category-spotlight-card/products/product';
import { useGetIRBestList } from '@/components/app/home/services/hooks';

export default function Products() {
  const { data } = useGetIRBestList({ iranian: true, limit: 4 });
  if (!data) {
    return null;
  }
  return (
    <Container center className='items-end gap-2'>
      <Container center className='flex-col gap-2'>
        {data?.map((item, index) => {
          if (index < 2) {
            return <Product cover={item?.icon} key={item.id} slug={item.slug} />;
          }
        })}
      </Container>
      <Container center className='flex-col gap-2 pb-3'>
        {data?.map((item, index) => {
          if (index >= 2) {
            return <Product cover={item?.icon} key={item.id} slug={item.slug} />;
          }
        })}
      </Container>
    </Container>
  );
}
