import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import Tooltip from '@/components/@base/tooltip';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import Props from '@/components/shared/components/medal/type';

import imagesList from '@/constant/images-list';

export default function Medal({ icon, title, value ,itemID=null}: Props) {
  return (
    <>
      <Container
        className='relative h-[56px] w-[48px]'
        data-tooltip-id={itemID ?? title}
        data-tooltip-content={title}
      >
        <XImage
          src={icon?.publicLink || imagesList.icons.medal}
          alt={title}
          width={48}
          height={56}
        />
        <Text
          className='absolute top-[22px] w-full pt-1 text-white'
          size={SIZE_ENUM.XS}
          bold
          center
        >
          {value}
        </Text>
      </Container>
      <Tooltip id={itemID ?? title} />
    </>
  );
}
