'use client';
import Link from 'next/link';

import Card, { CardMedia } from '@/components/@base/card';
import Carousel, { CarouselItem } from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import { useGetStoryList } from '@/components/app/home/services/hooks';

import { DATA_CY_STORY_SLIDER } from '@/constant/cypress';

export default function Slider() {
  const { data } = useGetStoryList();

  if (!data) {
    return null;
  }
  return (
    <Container className='mt-7 pr-4 md:p-4 md:pr-0'>
      <Carousel className='!overflow-visible [&_.swiper-wrapper]:gap-5' slidesPerView='auto'>
        {data?.map((item) => (
          <CarouselItem data-cy={DATA_CY_STORY_SLIDER} key={item.id} className='!w-[280px]'>
            <Card className='relative h-[454px] w-[280px] rounded-lg' noBorder noShadow>
              <Link href={item.url} prefetch={false}>
                <CardMedia src={item.image.publicLink} alt={item.title} fill />
              </Link>
            </Card>
          </CarouselItem>
        ))}
      </Carousel>
    </Container>
  );
}
