'use client';
import Card, { CardContent } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import GradientButton from '@/components/app/home/components/hero/@shared/button';
import Carousel from '@/components/app/home/components/hero/rotating-banner-card/carousel';
import { useGetBestOfCateList } from '@/components/app/home/services/hooks';

import imagesList from '@/constant/images-list';
import localization from '@/constant/localization';

export default function RotatingBannerCard() {
  const { data } = useGetBestOfCateList();
  if (!data) {
    return null;
  }
  return (
    <Card className='relative h-[440px] w-[326px] rounded-2xl border-[3px] border-white pb-11 pt-[46px] shadow-[0px_5px_35px_0px_rgba(34,36,49,0.18)]'>
      <XImage
        src={imagesList.backgrounds.rotatingSliderBG}
        alt={localization.zhaket}
        fill
        className='-z-[1]'
      />
      <CardContent className='flex flex-col items-center p-0'>
        <Carousel slides={data.slides} />
        <Container center className='flex-col pt-9'>
          <Text bold className='text-[17px] text-white'>
            {data.title}
          </Text>
          <Text medium className='pt-1 text-silver'>
            {data.description}
          </Text>

          <GradientButton
            label={localization.viewAll}
            url='/web/category/ecommerce-woocommerce'
          />
        </Container>
      </CardContent>
    </Card>
  );
}
