'use client';
import Link from 'next/link';
import { BiHeart } from 'react-icons/bi';

import { useClientSmallScreen } from '@/lib/user-agent/client';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import { VARIANT_ENUM } from '@/components/@base/button/type';
import Carousel, { CarouselItem } from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import { HEADING_TYPE } from '@/components/@base/typography/heading/type';
import ProductsCarousel from '@/components/app/home/components/@shared/carousel';
import SeeAllButton from '@/components/app/home/components/@shared/see-all-button';
import { useGetBundleList } from '@/components/app/home/services/hooks';
import { BundleCard } from '@/components/shared/components/cards';
import RoundedIcon from '@/components/shared/components/icon/rounded';
import IconTextCombo from '@/components/shared/components/icon-text-combo';

import { DATA_CY_BUNDLES, DATA_CY_BUNDLES_SLIDER } from '@/constant/cypress';
import localization, { pageLevelLocalization } from '@/constant/localization';

const { home: homeLocalization } = pageLevelLocalization;
export default function Bundles() {
  const isSmallScreen = useClientSmallScreen();
  const { data } = useGetBundleList();

  if (!data) {
    return null;
  }
  return (
    <Container
      data-cy={DATA_CY_BUNDLES}
      center
      className='bg-[#171922] md:mt-[75px] md:pb-6 [&>*]:w-full'
    >
      <Container className='flex max-w-[1279px] flex-col gap-6 py-6 md:py-12'>
        <Container center className='justify-between px-4 md:px-0 md:py-2'>
          <IconTextCombo
            Icon={<RoundedIcon icon={<BiHeart color='#fff' size={18} />} isDark />}
            textSize={SIZE_ENUM.XL}
            headingType={HEADING_TYPE.H2}
            title={homeLocalization.fantasticOffers}
            textClassName='text-white font-bold text-xl md:text-[22px]'
          />
          <SeeAllButton url='/web/category/amazing-bundles/?sort_by=top_sales' theme='dark' className='!h-[45px]'/>
        </Container>

        <ProductsCarousel className='pr-4 md:pr-0'>
          {data?.map((item) => {
            return (
              <CarouselItem
                data-cy={DATA_CY_BUNDLES_SLIDER}
                key={item.id}
                style={{ width: `${isSmallScreen ? 280 : 410}px` }}
              >
                <BundleCard {...item} />
              </CarouselItem>
            );
          })}
        </ProductsCarousel>
      </Container>
    </Container>
  );
}
