import Link from 'next/link';

import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import Props from '@/components/app/home/components/hero/@shared/button/type';

const GradientButton = ({ label, url, Icon, className, wrapperClassName }: Props) => (
  <Link
    href={url}
    prefetch={false}
    className={cn('relative mt-6 h-11 w-[120px] overflow-hidden', wrapperClassName)}
  >
    <Button
      size={SIZE_ENUM.SM}
      className={cn(
        'absolute inset-0 h-11 bg-gradient-to-r from-[#FFC107] to-[#FF9737] text-[15px] font-bold text-white opacity-100 transition-opacity duration-300 hover:opacity-0',
        className,
      )}
      Icon={Icon}
    >
      {label}
    </Button>
    <Button
      size={SIZE_ENUM.SM}
      className={cn(
        'absolute inset-0 h-11 bg-gradient-to-r from-[#FF9737] to-[#FFC107] text-[15px] font-bold text-white opacity-0 transition-opacity duration-300 hover:opacity-100',
        className,
      )}
      Icon={Icon}
    >
      {label}
    </Button>
  </Link>
);
export default GradientButton;
