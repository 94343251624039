'use client';
import { useEffect, useState } from 'react';

import cn from '@/lib/clsxm';
import { useClientSmallScreen } from '@/lib/user-agent/client';

import Container from '@/components/@base/container';
import { useGetAdsBanner } from '@/components/app/home/services/hooks';
import { ADS_BANNER_TYPE } from '@/components/app/home/services/types';
import { Banner } from '@/components/shared/components/cards';
import { BANNER_TYPE } from '@/components/shared/components/cards/banner/type';

import { DATA_CY_ADDS_BANNER } from '@/constant/cypress';
import ImageModel from '@/models/image.model';

export default function AdsBanner() {
  const { data } = useGetAdsBanner({ type: ADS_BANNER_TYPE.ONE_COLUMN });
  const [bannerImage, setBannerImage] = useState<ImageModel | undefined>();
  const isSmallScreen = useClientSmallScreen();
  useEffect(() => {
    if (data) {
      if (isSmallScreen) {
        setBannerImage(data.mobileImage);
      } else {
        setBannerImage(data.desktopImage);
      }
    }
  }, [data, isSmallScreen]);

  if (!data) {
    return null;
  }
  return (
    <Container
      center
      data-cy={DATA_CY_ADDS_BANNER}
      className='mt-5 w-full max-w-[1279px] px-4 md:mt-20 md:px-0'
      id='zhktopads'
    >
      <Banner
        image={bannerImage?.publicLink}
        title={data?.title}
        type={BANNER_TYPE.IMAGE}
        link={data.url}
        target="_blank"
        rel="nofollow"
        className={cn('shadow-0 w-full border-[1.5px] border-white object-cover')}
        width={!isSmallScreen ? 1279 : 400}
        height={!isSmallScreen ? 160 : 212}
      />
    </Container>
  );
}
