import Link from 'next/link';
import { BiHeart } from 'react-icons/bi';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import { VARIANT_ENUM } from '@/components/@base/button/type';
import Container from '@/components/@base/container';
import XImage from '@/components/@base/x-image';
import Best from '@/components/app/home/components/best-products/top/best';
import Props from '@/components/app/home/components/best-products/top/type';
import IconicProductCard from '@/components/shared/components/cards/iconic-product';
import RoundedIcon from '@/components/shared/components/icon/rounded';
import IconTextCombo from '@/components/shared/components/icon-text-combo';

export default function Top({ title, icon, products, btnTitle, url }: Props) {
  const newProductList = products?.splice(1, products.length - 1);
  return (
    <Container
      center
      className='flex-col rounded-xl bg-white p-5 shadow-[0px_5px_25px_0px_rgba(126,137,155,0.05)] md:p-[30px] [&>*]:w-full'
    >
      <IconTextCombo
        Icon={
          <RoundedIcon
            icon={
              icon?.publicLink ? (
                <XImage width={16} height={16} alt={icon.title} src={icon.publicLink} />
              ) : (
                <BiHeart />
              )
            }
          />
        }
        title={title}
        textSize={SIZE_ENUM.LG}
        wrapperClassName='pb-0 pb-5 md:pb-7'
        textClassName='font-bold'
        // textType='h2'
      />
      <Best {...products?.[0]} />
      <Container className='flex flex-col py-3'>
        {newProductList?.map((item) => (
          <IconicProductCard noBorder key={item.id} data={item} className='p-0' />
        ))}
      </Container>
      <Link href={url} className='w-full' prefetch={false}>
        <Button className='w-full font-bold' size={SIZE_ENUM.MD} variant={VARIANT_ENUM.SOUL}>
          {btnTitle}
        </Button>
      </Link>
    </Container>
  );
}
