import Link from 'next/link';

import Card, { CardContent, CardMedia } from '@/components/@base/card';
import Props from '@/components/app/home/components/hero/category-spotlight-card/products/product/type';

export default function Product({ cover, slug }: Props) {
  return (
    <Card noBorder className='rounded-md bg-white shadow-md'>
      <Link href={`/web/${slug}`} prefetch={false}>
        <CardContent>
          <CardMedia src={cover.publicLink} alt={cover.title} width={40} height={40} />
        </CardContent>
      </Link>
    </Card>
  );
}
