import Link from 'next/link';

import Button from '@/components/@base/button';
import { VARIANT_ENUM } from '@/components/@base/button/type';

import localization from '@/constant/localization';

export default function Actions() {
  return (
    <>
      <Button
        variant={VARIANT_ENUM.SOUL}
        className='mdLw-[131px] h-[45px] w-full border-none bg-transparent text-sm font-bold text-[#787676] shadow-none'
      >
        <Link href='/landing/become-seller/' passHref={true} target='_blank' prefetch={false}>
          {localization.moreInfo}
        </Link>
      </Button>
      <Button
        className='hover-text-[#B47510] mdLw-[131px] h-[45px] w-full text-sm font-bold hover:bg-[#FFE6C0]'
        variant={VARIANT_ENUM.DARK_ORANGE}
      >
        <Link href='/landing/become-seller/' passHref={true} target='_blank' prefetch={false}>
          {localization.register}
        </Link>
      </Button>
    </>
  );
}
