'use client';

import Container from '@/components/@base/container';
import Course from '@/components/app/home/components/courses/course';
import { useGetCourseList } from '@/components/app/home/services/hooks';
import { ADS_BANNER_TYPE } from '@/components/app/home/services/types';

import { DATA_CY_COURSES } from '@/constant/cypress';

export default function Courses() {
  const { data } = useGetCourseList({ type: ADS_BANNER_TYPE.THREE_COLUMN });
  if (!data) {
    return null;
  }
  return (
    <Container
      data-cy={DATA_CY_COURSES}
      center
      className='max-w-[1279px] flex-col gap-5 p-4 pt-0 md:flex-row md:p-0 md:pb-20 md:pt-[52px] [&>*]:w-full'
    >
      {data.map((item,index) => {
        let courseId='';
        if (index === 0) {
          courseId= 'zhkbotads-ri'
        }else if (index === 1) {
          courseId= 'zhkbotads-mi'
        }else {
          courseId= 'zhkbotads-le'
        }

        return <Course sectionId={courseId} key={item.id} {...item} />;
      })}
    </Container>
  );
}
