import Link from 'next/link';
import { FaArrowLeft } from 'react-icons/fa6';

import cn from '@/lib/clsxm';

import Button from '@/components/@base/button';
import { VARIANT_ENUM } from '@/components/@base/button/type';
import Props from '@/components/app/home/components/@shared/see-all-button/type';

import localization from '@/constant/localization';

export default function SeeAllButton({ url = '/', theme = 'light',className }: Props) {
  return (
    <Link href={url} className='hidden shrink-0 md:block'>
      <Button
        variant={VARIANT_ENUM.OUTLINED}
        className={cn(
          className,
          'flex-row-reverse text-sm font-bold hover:bg-[#FF9606] hover:text-[#FFFFFF]',
          theme === 'dark' && 'border-[#544C45] text-[#FF9606]',
          theme === 'light' && 'border-[#F1F1F1] text-[#FF7B01]',
        )}
        Icon={<FaArrowLeft />}
      >
        {localization.viewAll}
      </Button>
    </Link>
  );
}
