import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import Medal from '@/components/shared/components/medal';
import Props from '@/components/shared/components/medals/type';

export default function Medals({ medals, className }: Props) {
  return (
    <Container center className={cn('justify-around gap-2', className)}>
      {medals?.map((item, index) => {
        return (
          <Medal
            itemID={item.id}
            key={item.id ?? index}
            title={item.title}
            icon={item.icon}
            value={item.value}
          />
        );
      })}
    </Container>
  );
}
