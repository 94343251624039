import Link from 'next/link';
import { MdKeyboardArrowLeft } from 'react-icons/md';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import Props from '@/components/app/home/components/best-seller/info/type';
import IconTextCombo from '@/components/shared/components/icon-text-combo';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import imagesList from '@/constant/images-list';
import localization, { pageLevelLocalization } from '@/constant/localization';

const { home: homeLocalization } = pageLevelLocalization;
export default function Info({ avatar, store }: Props) {
  return (
    <Container className='z-20 flex w-full max-w-[923px] gap-4'>
      <Container className='relative h-[54px] min-w-[54px] rounded-full'>
        <Link href={`store/web/${store?.slug}`} prefetch={false}>
          <XImage src={avatar?.publicLink} fill alt={avatar?.title} className='rounded-full' />
        </Link>
      </Container>

      <Container>
        <IconTextCombo
          Icon={
            <XImage
              src={imagesList.icons.fireIcon}
              alt={localization.zhaket}
              width={22}
              height={22}
            />
          }
          title={homeLocalization.bestOfWeek}
          textClassName='text-[#5B5C60]'
          textSize={SIZE_ENUM.SM}
          wrapperClassName='p-0 gap-1'
        />
        <Container center className='gap-1'>
          <Text size={SIZE_ENUM.XL} className='text-[#424244]' medium>
            <Link href={`store/web/${store?.slug}`} className='font-bold md:text-xl' prefetch={false}>
              {store?.name}
            </Link>
          </Text>
          <MdKeyboardArrowLeft color={ICON_COLOR.orange} size={ICON_SIZE.sm} />
        </Container>
      </Container>
    </Container>
  );
}
