import {
  TabGroup as HeadlessUiTabGroup,
  TabList as HeadlessUiTabList,
  TabPanels as HeadlessUiTabPanels,
} from '@headlessui/react';

import Panel from '@/components/@base/tab/panel';
import Tab from '@/components/@base/tab/tab';
import { ListProps, PanelsProps, Props } from '@/components/@base/tab/type';

export default function TabGroup({ children, ...props }: Props) {
  return <HeadlessUiTabGroup {...props}>{children}</HeadlessUiTabGroup>;
}

function Tabs({ children, ...props }: ListProps) {
  return <HeadlessUiTabList {...props}>{children}</HeadlessUiTabList>;
}

function TabPanels({ children, ...props }: PanelsProps) {
  return <HeadlessUiTabPanels {...props}>{children}</HeadlessUiTabPanels>;
}

export { Tab, Panel as TabPanel, TabPanels, Tabs };
