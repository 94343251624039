import Container from '@/components/@base/container';
import XImage from '@/components/@base/x-image';

import imagesList from '@/constant/images-list';
import localization from '@/constant/localization';

export default function Background() {
  return (
    <Container
      center
      className='absolute z-1 w-full justify-end overflow-hidden p-6 md:justify-start md:pt-14'
    >
      <XImage
        src={imagesList.backgrounds.bestSellerBg}
        alt={localization.zhaket}
        width={210}
        height={180}
      />
    </Container>
  );
}
