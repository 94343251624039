import Link from 'next/link';

import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import { CardAction } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import Actions from '@/components/shared/components/cards/@shared/actions';
import Props from '@/components/shared/components/cards/demo/content/hover-mode/type';
import IconTextCombo from '@/components/shared/components/icon-text-combo';
import PriceView from '@/components/shared/components/price-view';
import IconLinkCombo from "@/components/shared/components/icon-link-combo";

export default function HoverMode({
  isHovered,
  productTitle,
  title,
  price,
  lastPrice,
  discount,
  saleEndedAt,
  icon,
  slug,
  id,
  demoId,
  sellable,
}: Props) {
  return (
    <Container
      className={cn(
        'absolute bottom-0 z-50 h-[145px] w-full bg-white p-4 transition duration-300 md:bottom-[-145px]',
        isHovered && 'z-50 md:bottom-0',
      )}
    >
      <Container center className='w-full justify-between'>
        <Container>
          <Link href={`/web/${slug}/demo?demo_id=${demoId}`} passHref prefetch={false}>
            <Text size={SIZE_ENUM.MD} className='line-clamp-1 text-[#424244]'>
              {title}
            </Text>
          </Link>
          <IconLinkCombo
            Icon={<XImage src={icon?.publicLink} width={22} height={22} alt={icon?.title} />}
            textSize={SIZE_ENUM.XS}
            textClassName='text-[#76767C] w-full max-w-[150px] line-clamp-1'
            title={productTitle}
            link={`/web/${slug}`}
            target='_blank'
          />
        </Container>

        <PriceView
          showDiscountLabel
          sellable={sellable}
          price={price}
          lastPrice={lastPrice}
          discount={discount}
          saleEndedAt={saleEndedAt}
          className='flex items-center gap-2'
          priceClassName='text-[#5B5C60] font-bold text-md'
          currencyClassName='text-[11px] text-[#76767C]'
        />
      </Container>
      <CardAction center className='mt-3 w-full gap-2 p-0'>
        <Actions slug={slug} productId={id} demoId={demoId} />
      </CardAction>
    </Container>
  );
}
